import $axios from 'axios';

const findUserCenterUrl = "/user/findUserCenter";
const userPagingUrl = "/user/paging";
const userDetailsUrl = "/user/details";
const userUpdateUrl = "/user/update";
const cancelAuthorizationUrl = "/user/cancelAuthorization";
const findRoleDeptSelectUrl = "/user/findRoleDeptSelect";
const authorizationUrl = "/user/authorization";
const lockOrUnlockUrl = "/user/lockOrUnlock";
const registerUrl = "/register/register";
const updatePasswordUrl = "/user/updatePassword";
const findRoleSelectUrl = "/user/findRoleSelect";
const settingPriorityUrl = "/user/settingPriority";
const userDeleteUrl = "/user/delete";

// 我的名片
export const findUserCenterApi = () => {
    const token = sessionStorage.getItem('token');
    return $axios({
        method: 'POST',
        url: findUserCenterUrl,
        headers: {
            Authorization: token
        }
    })
}

// 分页查询
export const userPagingApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: userPagingUrl,
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 用户详情
export const userDetailsApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: userDetailsUrl,
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 修改用户
export const userUpdateApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: userUpdateUrl,
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 取消授权
export const cancelAuthorizationApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: cancelAuthorizationUrl,
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 查询角色和部门下拉框
export const findRoleDeptSelectApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: findRoleDeptSelectUrl,
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 授权
export const authorizationApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: authorizationUrl,
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 锁定或取消锁定
export const lockOrUnlockApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: lockOrUnlockUrl,
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 注册用户
export const registerApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: registerUrl,
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

// 修改密码
export const updatePasswordApi = (params) => {
    const token = sessionStorage.getItem('token');
    return $axios({
        method: 'POST',
        url: updatePasswordUrl,
        data: params,
        headers: {
            Authorization: token,
        }
    })
}

// 查询角色下拉框
export const findRoleSelectApi = (params) => {
    const token = sessionStorage.getItem('token');
    return $axios({
        method: 'POST',
        url: findRoleSelectUrl,
        data: params,
        headers: {
            Authorization: token,
        }
    })
}

// 设置公司与角色优先级
export const settingPriorityApi = (params) => {
    const token = sessionStorage.getItem('token');
    return $axios({
        method: 'POST',
        url: settingPriorityUrl,
        data: params,
        headers: {
            Authorization: token,
        }
    })
}

// 删除用户
export const userDeleteApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: userDeleteUrl,
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}
