<template>
  <!-- 回到顶部 -->
  <a-back-top />

  <a-layout id="components-layout-custom-trigger">

    <!-- 左边 -->
    <a-affix>
      <a-layout-sider :collapsed="this.$store.state.collapsed" theme="light" class="scrollbar-show" width="300" style="margin: 20px">
        <img class="logo" src="../../assets/logo/logo.png" draggable="false" alt="logo" v-if="!this.$store.state.collapsed" />
        <img class="logo" src="../../assets/logo/logoicon.png" draggable="false" alt="logoicon" v-else />

        <a-menu theme="light" mode="inline" :openKeys="this.$store.state.openKeys" :selectedKeys="this.$store.state.selectedKeys" @openChange="openCollapse" v-for="menu in this.$store.state.mainMenu" :key="menu.path">

          <a-menu-item :key="menu.path" v-if="menu.children.length === 0">
            <router-link :to="menu.path" />
            <Component :is="$icons[menu.icon]" />
            <span style="color: purple">{{ menu.menuTitle }}</span>
          </a-menu-item>

          <a-sub-menu :key="menu.path" v-else>
            <template #title>
            <span>
              <Component :is="$icons[menu.icon]" />
              <span style="color: blue">{{ menu.menuTitle }}</span>
            </span>
            </template>
            <a-menu-item v-for="children in menu.children" :key="menu.path.concat('/').concat(children.path)">
              <router-link :to="menu.path.concat('/').concat(children.path)" />
              <span style="color: palevioletred">{{ children.menuTitle }}</span>
            </a-menu-item>
          </a-sub-menu>

        </a-menu>
      </a-layout-sider>
    </a-affix>

    <!-- 右边 -->
    <a-layout style="margin-top: 20px; margin-bottom: 20px; margin-right: 20px;">

      <!-- 右边header -->
      <a-layout-header style="background: #fff; padding: 0;" theme="light">
        <menu-unfold-outlined
                v-if="this.$store.state.collapsed"
                class="trigger"
                @click="scalingMenu"
        />
        <menu-fold-outlined v-else class="trigger" @click="scalingMenu" />

        <a-select style="margin: 0 20px; width: 300px; color: mediumpurple;" placeholder="公司角色切换" :value="this.$store.state.roleSelectValue" @change="roleSelectChange">
          <a-select-option v-for="roleSelect in this.$store.state.roleSelectList" :key="roleSelect.value" :value=roleSelect.value>{{ roleSelect.label }}</a-select-option>
        </a-select>

        <div style="float: right;">
          <a-avatar :src="this.$store.state.avatar" size="large" style="color: #f56a00; background-color: #fde3cf; margin-right: 20px;">
            <template #icon><UserOutlined /></template>
          </a-avatar>

          <a-dropdown-button style="margin-right: 20px; margin-top: 15px;">
            <span>你好, {{ this.$store.state.loginName }}</span>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <setting-outlined />
                  <span @click="setting">&nbsp;设置</span>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item>
                  <LogoutOutlined />
                  <span @click="logout">&nbsp;退出登录</span>
                </a-menu-item>
              </a-menu>
            </template>
            <template #icon>
              <UserOutlined />
            </template>
          </a-dropdown-button>
        </div>

      </a-layout-header>

      <!-- 右边内容 -->
      <a-layout-content :style="{ marginTop: '20px', padding: '0px', background: '#fff', minHeight: '280px' }" >

        <a-tabs :activeKey="this.activeKey" hide-add type="editable-card" @edit="onEdit" @change="checkTab" size="small">
          <a-tab-pane v-for="pane in this.$store.state.panes" :key="pane.key" :closable="this.$store.state.panes.length!==1">
            <template #tab>
              <span><Component :is="$icons[pane.icon]" v-if="pane.icon !== null"/>{{pane.title}}</span>
            </template>
            <div style="padding: 0 15px 15px;">
<!--                            {{ pane.key }}-&#45;&#45;{{ pane.title }}-->
              <slot></slot>
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-layout-content>

      <!-- 右边footer -->
      <a-layout-footer>
        <div style="text-align: center">
          Copyright © julycloud.cn
        </div>
      </a-layout-footer>

    </a-layout>

  </a-layout>

  <a-modal v-model:visible="this.$store.state.showSetting" title="设置" style="width: 70%;" :footer="null" :afterClose="() => {this.$store.state.password = '';}" >
    <a-row :gutter="24">
      <a-col :span="8" :offset="8" style="margin-top: 100px;">
        <a-form-item label="修改密码">
          <a-input-password v-model:value="this.$store.state.password" style="width: 80%;" placeholder="" />
          <a-button type="primary" @click="confirmUpdate" style="width: 20%;">确定</a-button>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="24">
      <a-col :span="8" :offset="8" style="margin-top: 100px; margin-bottom: 200px;">
        <a-form-item label="修改优先级">
          <a-select style="width: 100%;" placeholder="" :value="this.$store.state.settingRoleSelectValue" @change="settingRoleSelectChange">
            <a-select-option v-for="roleSelect in this.$store.state.roleSelectList" :key="roleSelect.value" :value=roleSelect.value>{{ roleSelect.label }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
  </a-modal>

</template>

<script>
import * as MenuApi from '@/api/MenuApi.js';
import * as UserApi from '@/api/UserApi.js';
import {message} from "ant-design-vue";

export default {
  name: 'ControlPanel',

  components: {

  },

  data() {
    return {
      activeKey: this.$route.path,
    };
  },

  methods: {
    // 增加并选中tab
    async addTab(title, key, icon) {
      let isExist = false;
      this.$store.state.panes.forEach(pane => {
        if (pane.key === key) {
          isExist = true;
        }
      });
      if (!isExist) {
        this.$store.state.panes.push({
          title: title,
          key: key,
          icon: icon,
        });
        sessionStorage.setItem("panes", JSON.stringify(this.$store.state.panes));
      }

    },

    // 删除并选中tab
    removeTab(targetKey) {
      let removeIndex = 0;
      this.$store.state.panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          removeIndex = i;
        }
      });
      this.$store.state.panes = this.$store.state.panes.filter(pane => pane.key !== targetKey);
      sessionStorage.setItem("panes", JSON.stringify(this.$store.state.panes));
      if (this.activeKey === targetKey/* && this.$store.state.panes.length > 0*/) {
        if (removeIndex === 0) {
          this.$router.push(this.$store.state.panes[0].key);
        } else {
          this.$router.push(this.$store.state.panes[removeIndex - 1].key);
        }
      }

    },

    // 操作tab
    onEdit(targetKey) {
      this.removeTab(targetKey);
    },

    // 切换标签页
    checkTab(targetKey) {
      this.$router.push(targetKey);
    },

    // 菜单展开或收缩
    scalingMenu() {
      this.$store.state.collapsed = !this.$store.state.collapsed;
      sessionStorage.setItem("collapsed", JSON.stringify(this.$store.state.collapsed));
    },

    // 手风琴展开或收缩
    openCollapse(openKeys) {
      // 菜单收缩
      if (this.$store.state.collapsed) {
        return;
      }
      this.$store.state.openKeys = openKeys;
      sessionStorage.setItem("openKeys", JSON.stringify(this.$store.state.openKeys));
    },

    // 设置
    setting() {
      this.$store.state.showSetting = true;
    },

    // 确认修改
    confirmUpdate() {
      UserApi['updatePasswordApi'](`password=${this.$store.state.password}`)
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 200) {
            message.success(msg);
          } else {
            message.error(msg);
          }

        })
        .catch((e) => {
          message.error(e.message);
        });
    },

    // 登出
    logout() {
      sessionStorage.clear();
      this.$router.push("/login");
    },

    async setPanes() {
      if (sessionStorage.getItem("panes")) {
        this.$store.state.panes = JSON.parse(sessionStorage.getItem("panes"));
      }
    },
    async setCollapsed() {
      if (sessionStorage.getItem("collapsed")) {
        this.$store.state.collapsed = JSON.parse(sessionStorage.getItem("collapsed"));
      }
    },
    async setOpenKeys() {
      if (sessionStorage.getItem("openKeys")) {
        this.$store.state.openKeys = JSON.parse(sessionStorage.getItem("openKeys"));
      }
    },
    async setLoginInfo() {
      this.$store.state.loginName = sessionStorage.getItem('loginName');
      this.$store.state.avatar = sessionStorage.getItem('avatar');
    },

    async setMenu() {
      if (sessionStorage.getItem("mainMenu")) {
        this.$store.state.mainMenu = JSON.parse(sessionStorage.getItem("mainMenu"));
      } else {
        this.findMenu();
      }
    },

    async setRoleSelect() {
      if (sessionStorage.getItem("roleSelectList")) {
        this.$store.state.roleSelectList = JSON.parse(sessionStorage.getItem("roleSelectList"));
      } else {
        this.findRoleSelect();
      }

    },

    findMenu() {
      MenuApi['findMenuApi']()
      .then((result) => {
        let code = result.data.code;
        let msg = result.data.msg;
        if (code === 200) {
          this.$store.state.mainMenu = result.data.data.routes;

          sessionStorage.setItem("mainMenu", JSON.stringify(this.$store.state.mainMenu));

          // 刷新panes
          if (this.$store.state.mainMenu === null) {
            this.$store.state.panes = []
            sessionStorage.setItem("panes", JSON.stringify(this.$store.state.panes));
          }

          let key = [];
          this.$store.state.mainMenu.forEach((menu) => {
            if (menu.children.length === 0) {
              key.push(menu.path);
            } else {
              menu.children.forEach(child => {
                key.push(menu.path.concat('/').concat(child.path));
              })
            }
          });

          this.$store.state.panes = this.$store.state.panes.filter(pane => {
            return key.includes(pane.key)
          });
          sessionStorage.setItem("panes", JSON.stringify(this.$store.state.panes));
          this.$router.push("/usercenter");

        } else if (code === 401) {
          message.error(msg);
          sessionStorage.clear();
          this.$router.push("/login");
        }
      })
      .catch((e) => {
        message.error(e.message);
      })
    },

    findRoleSelect() {
      UserApi['findRoleSelectApi']()
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 200) {
            this.$store.state.roleSelectList = result.data.data;

            sessionStorage.setItem("roleSelectList", JSON.stringify(this.$store.state.roleSelectList));
          } else {
            message.error(msg);
          }
        })
        .catch((e) => {
          message.error(e.message);
        })
    },

    roleSelectChange(option) {
      this.$store.state.roleSelectValue = option;
      let [companyId, roleId] = option.split(',')
      sessionStorage.setItem('companyId', companyId);
      sessionStorage.setItem('roleId', roleId);
      this.findMenu();
    },

    settingRoleSelectChange(option) {
      this.$store.state.settingRoleSelectValue = option;
      let [companyId, roleId] = option.split(',')
      this.settingPriority(companyId, roleId);
    },

    settingPriority(companyId, roleId) {
      let params = {companyId: companyId, roleId: roleId};
      UserApi['settingPriorityApi'](params)
        .then((result) => {
          let code = result.data.code;
          let msg = result.data.msg;
          if (code === 200) {
            message.success(msg);
          } else {
            message.error(msg);
          }
        })
        .catch((e) => {
          message.error(e.message);
        })
    },


  },

  // 渲染之前调用
  created() {
  },

  // 渲染之后调用
  mounted() {
  },

  // keep-alive 第一次进入页面触发 (created > mounted > activated) 再次进入页面只触发 activated
  activated() {
    this.setPanes();
    this.setCollapsed();
    this.setOpenKeys();
    this.setLoginInfo();
    this.addTab(this.$route.meta['title'], this.$route.path, this.$route.meta['icon']);
    // 设置菜单
    this.setMenu();
    // 设置角色下拉框
    this.setRoleSelect().then(() => // 先渲染列表数据，后渲染选中
            this.$store.state.roleSelectValue = sessionStorage.getItem("companyId") + ',' + sessionStorage.getItem("roleId"));


  },

}
</script>

<style>
/*------------------------------------------------------------*/
#components-layout-custom-trigger {
  width: 100%;
  min-height: 100vh;
}

#components-layout-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-custom-trigger .logo {
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  margin: 20px;
}

/*------------------------------------------------------------*/
/*滚动条样式*/
.scrollbar-show::-webkit-scrollbar {
  width: 5px;
}

.scrollbar-show {
  height: 95vh;
}

/*滚动条的宽度*/
::-webkit-scrollbar {
  /* 垂直滚动条宽度*/
  width: 10px;
  /* 水平滚动条宽度*/
  height: 10px;
}

/*滚动条凹槽的颜色，还可以设置边框属性*/
::-webkit-scrollbar-track-piece {
  background-color: #fff;
}

/*滚动条的设置*/
::-webkit-scrollbar-thumb {
  background-color: aliceblue;
  background-clip: padding-box;
  min-height: 20px;
  border-radius: 10px;
}

/*移动上去时的颜色*/
::-webkit-scrollbar-thumb:hover {
  background-color: lightblue;
}

/*移动上去才显示滚动条*/
.ant-layout-sider {
  overflow: hidden;
}
.ant-layout-sider:hover {
  overflow: auto;
}

</style>
